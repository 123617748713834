import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksIzq from "components/Header/HeaderLinksIzq.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/productos.js";

// Sections for this page
import logo from "assets/img/logoBraese.png";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import Card from "components/Card/Card.js";

import "./Sections/center.css";

import chocolateBarra1 from "assets/img/fotos/chocolatesBarra1.jpg";
import chocolateBarra2 from "assets/img/fotos/chocolatesBarra2.jpg";

import chocolateRama1 from "assets/img/fotos/chocolate1.jpg";
import chocolateRama2 from "assets/img/fotos/chocolatesRama1.jpg";

import bombones1 from "assets/img/fotos/bombones2.jpg";
import bombones2 from "assets/img/fotos/bombones3.jpg";

import licores1 from "assets/img/fotos/licor3.jpg";
import licores2 from "assets/img/fotos/licor6.jpg";

import dulces1 from "assets/img/fotos/dulces1.jpg";
import dulces2 from "assets/img/fotos/dulces3.jpg";

import reposteria1 from "assets/img/fotos/reposteria2.jpg";
import reposteria2 from "assets/img/fotos/reposteria3.jpg";

const useStyles = makeStyles(styles);



export default function Productos(props) {

  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        color="transparent"
        leftLinks={<HeaderLinksIzq />}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "inherit",
        }}
        {...rest}
      ></Header>
      <Parallax filter image={require("assets/img/fotos/foto1.jpg")}>
        <div className={classes.container}>
          <img alt="..." src={logo} className="center"></img>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.containerDos}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <NavPills
                color="success"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 8, md: 8 },
                }}
                tabs={[
                  {
                    tabButton: "Chocolates",
                    tabContent: (
                      <span>
                        <h1 className={classes.titlepal}>Chocolates</h1>
                        <p className={classes.descriptionDos}>
                          Barras compactas de chocolate de leche, blanco o
                          semiamargo y rellenas con un baño de chocolate.
                          Elaboradas artesanalmente en variedad de sabores:
                          almendras, nueces, maní, avellanas, castañas, cereal,
                          arroz inflado, coco, pasas, ciruelas, higos, caramelo,
                          marroc, menta, etc,
                        </p>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={chocolateBarra1}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={chocolateBarra2}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "Chocolate en Rama",
                    tabContent: (
                      <span>
                        <h1 className={classes.titlepal}>Chocolate en Rama</h1>
                        <p className={classes.descriptionDos}>
                          El chocolate en rama es elaborado artesanalmente sobre
                          una mesa de mármol con el más puro chocolate de leche
                          o blanco. Con una espátula se forman los trozos
                          “aireados” con la característica forma arrugada.
                        </p>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={chocolateRama1}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={chocolateRama2}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                        </GridContainer>{" "}
                      </span>
                    ),
                  },
                  {
                    tabButton: "Bombones",
                    tabContent: (
                      <span>
                        <h1 className={classes.titlepal}>Bombones finos</h1>
                        <p className={classes.descriptionDos}>
                          Elaborados con frutas (almendras, nueces, avellanas,
                          ciruelas, higos, naranjitas, etc.) y rellenos
                          (praliné, mousse, dulce de leche, trufa, moca, café,
                          mentas, marroc, cremas, frutas, etc).
                        </p>
                        <p className={classes.description}>
                          Presentación en cajas surtidas de diversos tamaños, en
                          3 variedades:{<br />} • Bombones TORRECILLAS: Surtido
                          de de bombones especiales (frutas secas y tiernas).
                          {<br />} • Bombones LOS ALERCES: Surtido de bombones
                          Bombones LA HOYA: Surtido combinado (bombones
                          especiales y rellenos).
                        </p>
                        <p className={classes.description}>
                          Además bombones especiales en cajas de diversos
                          tamaños:{<br />} • Cerisettes (cerezas con licor)
                          {<br />} • Almendras con chocolate.{<br />} • Nueces
                          Glaseadas.{<br />} • Avellanas con chocolate.
                        </p>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={bombones1}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={bombones2}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                        </GridContainer>{" "}
                      </span>
                    ),
                  },
                  {
                    tabButton: "Licores",
                    tabContent: (
                      <span>
                        <h1 className={classes.titlepal}>
                          Licores Artesanales
                        </h1>
                        <p className={classes.descriptionDos}>
                          Elaborados mediante la maceración de frutas finas de
                          la zona, en toneles especiales de madera de la región,
                          a partir de ancestrales recetas europeas.{<br />} La
                          graduación alcohólica varía en función del tipo de
                          licor, entre 15 y 28 grados.
                        </p>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={licores1}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={licores2}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                        </GridContainer>{" "}
                      </span>
                    ),
                  },
                  {
                    tabButton: "Dulces",
                    tabContent: (
                      <span>
                        <h1 className={classes.titlepal}>
                          Dulces y Mermeladas
                        </h1>
                        <p className={classes.descriptionDos}>
                          Elaborados en forma totalmente artesanal, a partir de
                          frutas finas de la región y azúcar, sin agregados de
                          aditivos artificiales ni conservantes, en variedad de
                          sabores:
                        </p>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <p className={classes.descriptionDos}>
                              • Frambuesa {<br />} • Frutilla{<br />} • Rosa
                              Mosqueta{<br />} • Guinda{<br />} • Cereza{<br />}{" "}
                              • Sauco
                            </p>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <p className={classes.descriptionDos}>
                              • Corinto{<br />} • Grosella {<br />}• Ciruela{" "}
                              {<br />}• Calafate{<br />} • Cassis{<br />} •
                              Frutos del Bosque
                            </p>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img alt="..." src={dulces1} className={classes.foto}></img>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img alt="..." src={dulces2} className={classes.foto}></img>
                            </Card>
                          </GridItem>
                        </GridContainer>{" "}
                      </span>
                    ),
                  },
                  {
                    tabButton: "Reposteria",
                    tabContent: (
                      <span>
                        <h1 className={classes.titlepal}>
                          Budines, postres y repostería
                        </h1>
                        <p className={classes.description}>
                          • Budín de Nuez {<br />}• Budín de Limón {<br />}•
                          Torna (Galesa) {<br />}• Stollen (Pan Dulce Alemán){" "}
                          {<br />}• Turrón Suizo (Relleno de mousse con
                          almendras bañado en chocolate) {<br />}• Turrón
                          Tulipán (Relleno de mousse con cerezas bañado en
                          chocolate) {<br />}• Coquitos: Bocaditos de merengue
                          de coco bañado en chocolate {<br />}• Crocantitas:
                          Bocaditos de merengue de almendra bañado en chocolate{" "}
                          {<br />}• Tartas de Manzana y Guinda {<br />}• Masitas
                          artesanales: de miel, almendras, chocolate, manteca,
                          lebkuchen, etc. {<br />}• Turrones Navideños: de
                          almendras, maní y castañas.
                        </p>
                        <GridContainer>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={reposteria1}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6}>
                            <Card>
                              <img
                                alt="..."
                                src={reposteria2}
                                className={classes.foto}
                              ></img>
                            </Card>
                          </GridItem>
                        </GridContainer>{" "}
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>

          <hr />
        </div>
      </div>
      <Footer />
    </div>
  );
}
