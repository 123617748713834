import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";



// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import chocolates from "assets/img/fotos/chocolatesBarra3.jpg";
import bombones from "assets/img/fotos/bombones.jpg";
import dulces from "assets/img/fotos/dulces4.jpg";
import licores from "assets/img/fotos/licor5.jpg";

import SectionCarousel from "./SectionCarousel";

const useStyles = makeStyles(styles);

export default function NuestrosProductos() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.descriptionDos}>
              {" "}
              Desde 1976 Productos Regionales Braese es sinónimo de atención y
              calidad en la elaboración de productos artesanales en Esquel.
            </h3>
          </GridItem>
          <Hidden smDown>
            <GridItem xs={12} sm={12} md={12}>
              <SectionCarousel />
            </GridItem>
          </Hidden>

          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={chocolates} alt="..." className={imageClasses} />
              </GridItem>

              <CardBody>
                <h4 className={classes.cardTitle}>
                  Chocolates
                  <br />
                </h4>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Link to="productos" style={{ color: "inherit" }}>
                  <Button>Ver mas</Button>
                </Link>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={bombones} alt="..." className={imageClasses} />
              </GridItem>

              <CardBody>
                <h4 className={classes.cardTitle}>
                  Bombones
                  <br />
                </h4>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Link to="productos" style={{ color: "inherit" }}>
                  <Button>Ver mas</Button>
                </Link>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={licores} alt="..." className={imageClasses} />
              </GridItem>

              <CardBody>
                <h4 className={classes.cardTitle}>
                  Licores
                  <br />
                </h4>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Link to="productos" style={{ color: "inherit" }}>
                  <Button>Ver mas</Button>
                </Link>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={dulces} alt="..." className={imageClasses} />
              </GridItem>

              <CardBody>
                <h4 className={classes.cardTitle}>
                  Dulces
                  <br />
                </h4>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Link to="productos" style={{ color: "inherit" }}>
                  <Button>Ver mas</Button>
                </Link>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
