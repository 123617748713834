import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksIzq from "components/Header/HeaderLinksIzq.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/historia.js";

// Sections for this page
import logo from "assets/img/logo.png";

import "./Sections/center.css";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Ubicacion(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<HeaderLinksIzq />}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "inherit",
        }}
        {...rest}
      ></Header>
      <Parallax filter image={require("assets/img/fotos/foto1.jpg")}>
        <div className={classes.container}>
          <img src={logo} alt="..." className="center"></img>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.containerDos}>
          <h1 className={classes.titlepal}>Nuestros Locales</h1>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.titlepal}> Chocolates Braese </h1>
              <h2 className={classes.titlepal}> 9 de Julio 1540 </h2>
              <h3 className={classes.titlepal}> Esquel, Chubut.</h3>
              <Button
                target="_blank"
                className={classes.boton}
                href="https://g.page/Chocolates-Braese?share"
              >
                {" "}
                Ver Ubicacion{" "}
              </Button>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.titlepal}> Chocolates Braese </h1>
              <h2 className={classes.titlepal}> 9 de Julio 1016</h2>
              <h3 className={classes.titlepal}> Esquel, Chubut.</h3>
              <Button
                target="_blank"
                className={classes.boton}
                href="https://goo.gl/maps/VBd1BKucEXXsZ3B8A"
              >
                {" "}
                Ver Ubicacion{" "}
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
