import React from 'react';
import Facebook from "components/Facebook";

function FeedFacebook() {
  return (
    <div>
      <Facebook/>
    </div>
  );
}

export default FeedFacebook;