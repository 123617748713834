import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import Productos from "views/Productos/Productos.js";
import Historia from "views/Historia/Historia.js";
import Ubicacion from "views/Ubicacion/Ubicacion.js";
import Contacto from "views/Contacto/Contacto.js";
import ScrollToTop from "components/ScrollToTop.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <ScrollToTop />
    <Switch>
      <Route path="/productos" component={Productos} />
      <Route path="/historia" component={Historia} />
      <Route path="/locales" component={Ubicacion} />
      <Route path="/contacto" component={Contacto} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
