import { container } from "assets/jss/material-kit-react.js";

const carouselStyle = {
  section: {
    marginTop: "20px",
    padding: "0 0",
   },
  container,
  marginAuto: {
    marginRight: "auto !important",
    marginLeft: "auto !important",
  },


};

export default carouselStyle;
