import React from 'react';

function Facebook() {
  return (
    <div
      className="fb-page"
      data-href="https://www.facebook.com/chocolatesbraese"
      data-tabs="timeline"
      data-width="600"
      data-height=""
      data-small-header="true"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    >
      <blockquote
        cite="https://www.facebook.com/chocolatesbraese"
        class="fb-xfbml-parse-ignore"
      >
        <a href="https://www.facebook.com/chocolatesbraese">
          Chocolates Braese Facebook
        </a>
      </blockquote>
    </div>
    );
}

export default Facebook;