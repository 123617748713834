import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FeedFacebook from "./FeedFacebook";
import Map from "components/Map";
import NuestrosProductos from "../Sections/NuestrosProductos";

import foto from "assets/img/fotos/portada.jpg";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <hr />
          <h1 className={classes.titlepal}>El chocolate de Esquel</h1>
          <hr />
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <img
            src={foto}
            alt="Chocolateria Braese"
            className={classes.foto}
          ></img>
        </GridItem>
      </GridContainer>
      <NuestrosProductos />

      <Hidden mdDown>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} className={classes.ventanas}>
            <FeedFacebook />
            <p className={classes.description}>Seguinos en Facebook</p>
            <p className={classes.alerta}>*Si usted no puede ver el perfil de Facebook es posible que esté usando un bloqueador de anuncios, desactivelo y vuelva a recargar la pagina.*</p>

          </GridItem>
          <GridItem xs={12} sm={12} md={6} className={classes.ventanas}>
            <Map />
            <p className={classes.description}>Nuestros Locales</p>
          </GridItem>
        </GridContainer>
      </Hidden>
    </div>
  );
}
