import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksIzq from "components/Header/HeaderLinksIzq.js";
import Parallax from "components/Parallax/Parallax.js";
import WorkSection from "./Sections/WorkSection.js";

import styles from "assets/jss/material-kit-react/views/historia.js";

// Sections for this page
import logo from "assets/img/logo.png";

import "./Sections/center.css";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Contacto(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<HeaderLinksIzq />}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "inherit",
        }}
        {...rest}
      ></Header>
      <Parallax filter image={require("assets/img/fotos/foto1.jpg")}>
        <div className={classes.container}>
          <img src={logo} alt="..." className="center"></img>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.containerDos}>
          <br />
          <h1 className={classes.titlepal}> Producto Regionales Braese </h1>
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
