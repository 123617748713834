import { titlepal } from "assets/jss/material-kit-react";
import { title, titledos } from "assets/jss/material-kit-react.js";

const productStyle = {
  section: {
    marginTop: "30px",
    marginBottom: "0px",
    padding: "25px",
    textAlign: "center",

  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "0px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: "'Raleway', sans-serif",
    textAlign: "center",
  },
  alerta: {
    color: "black",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "12px",
    fontFamily: "'Raleway', sans-serif",
    textAlign: "center",
  },
  titledos: {
    ...titledos,
    marginBottom: "1rem",
    marginTop: "0px",
    minHeight: "32px",
    textDecoration: "none",
  },
  titlepal:{
    ...titlepal,
    marginBottom: "100px",
    marginTop: "100px",
    fontFamily: "'Raleway', sans-serif",


  },
  ventanas: {
    marginBottom: "10px",
    marginTop: "50px",
  },
  foto: {
    marginTop: "7px",
    width: "100%",
    height: "325px",
    padding: "10px",
    border: "1px gray solid",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
};

export default productStyle;
