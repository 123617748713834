import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksIzq from "components/Header/HeaderLinksIzq.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/historia.js";

// Sections for this page
import logo from "assets/img/logo.png";

import "./Sections/center.css";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Historia(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        leftLinks={<HeaderLinksIzq />}
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "inherit",
        }}
        {...rest}
      ></Header>
      <Parallax filter image={require("assets/img/fotos/foto1.jpg")}>
        <div className={classes.container}>
          <img src={logo} alt="..." className="center"></img>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.containerDos}>
          <br />
          <hr />
          <h1 className={classes.titlepal}> Productos Regionales Braese </h1>
          <h2 className={classes.titlepal}>Nuestra Historia</h2>
          <hr />
          <p className={classes.description}>
            En el más puro ambiente patagónico, se combinan la tradición alemana
            traída por nuestros padres y abuelos, con la experiencia de más de
            35 años elaborando con los más altos niveles de calidad Chocolates,
            Bombones, Alfajores, Dulces, Mermeladas, Licores, Repostería y
            muchos productos más.
          </p>
          <p className={classes.description}>
            Nuestra filosofía consiste en elaborar productos regionales en forma
            artesanal, utilizando materias primas de primera calidad, sin
            descuidar en el proceso de elaboración los detalles que dan como
            resultado productos de comprobada excelencia, satisfaciendo a los
            consumidores más exigentes del mercado local, regional e
            internacional.
          </p>
          <p className={classes.description}>
            La trayectoria e historia de la familia Braese, desde las raíces de
            una panadería en Europa, pasando por la elaboración de productos de
            granja en Lago Futalaufquen, hasta nuestros días, nos ha convertido
            en representantes indiscutidos de la tradición de Esquel y toda la
            Comarca Los Alerces.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
