/*eslint-disable*/
import React from "react";

// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";



// @material-ui/icons
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>

      <ListItem className={classes.listItem}>
     
          <Button
            href="/"
            color="transparent"
            className={classes.navLink}
          >
            <HomeIcon className={classes.icons} /> Inicio
          </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
          <Link to="productos" style={{color: "inherit"}}>
            <Button
              color="transparent"
              className={classes.navLink}
            >
              <FavoriteIcon className={classes.icons} /> Productos
            </Button>
          </Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link to="historia" style={{color: "inherit"}} >
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <MenuBookIcon className={classes.icons} /> Historia
          </Button>
        </Link>
      </ListItem>
    

    </List>
    
  );
}
