import React, { Component } from "react";
import GoogleMaps from "simple-react-google-maps";


export default class Maps extends Component {
  
  render() {
    
    return(
      <div>
        <GoogleMaps
          apiKey={"AIzaSyB07EqCJw0pdIb1VW2sJwDWUSoExlzxHHk"}
          style={{ height: "500px", width: "475px" }}
          zoom={15}
          center={{
            lat: -42.909225,
            lng: -71.315968
          }}
          markers={[
            { lat: -42.909225, lng: -71.315968 },
            { lat: -42.913399, lng: -71.321520 }
          ]}
        />
      </div>
    );
  }
}